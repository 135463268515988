import React from "react";
import prof from "../../assets/img/icons/prof.svg";
import comfort from "../../assets/img/icons/comfort.svg";
import card from "../../assets/img/icons/card.svg";
import wifi from "../../assets/img/icons/wifi.svg";
import service from "../../assets/img/icons/service.svg";
import cancel from "../../assets/img/icons/cancel.svg";

interface WhyProps {
  id: number;
  logo: {
    image: any;
    alt: String;
  };
  name: String;
  desc: String;
}

const data: WhyProps[] = [
  {
    id: 1,
    name: "Professional & friendly",
    logo: {
      image: prof,
      alt: "Professional & friendly service",
    },
    desc: "Experience our courteous and competent service. Our devoted crew is committed to offering outstanding support with a friendly demeanor. To ensure effective and enjoyable interactions throughout your journey, professionalism is a priority for us. You may rely on us to be a trustworthy and amiable trip companion.",
  },
  {
    id: 2,
    name: "Comfortable fleet",
    logo: {
      image: comfort,
      alt: "Professional & friendly service",
    },
    desc: "Take advantage of our luxurious fleet for a superior traveling experience. Our well-kept automobiles provide a comfortable journey with roomy seating and opulent features. Your comfort is our top priority, and we make sure that every part of your travel is enjoyable. Our well-appointed fleet allows you to travel in comfort and style.",
  },
  {
    id: 3,
    name: "Competitive rates",
    logo: {
      image: card,
      alt: "Competitive rates",
    },
    desc: "Experience outstanding service at competitive rates. We offer affordable prices without compromising on quality. Enjoy a premium travel experience without breaking the bank. Discover the best of Croatia with our cost-effective offerings.",
  },
  {
    id: 4,
    name: "Free WiFi internet",
    logo: {
      image: wifi,
      alt: "Free WiFi internet",
    },
    desc: "With our free WiFi service, you may stay in contact while traveling. Take advantage of dependable connectivity to easily share your travels, browse the web, and check your emails. While experiencing Croatia, enjoy the comfort of keeping connected.",
  },
  {
    id: 5,
    name: "24 service",
    logo: {
      image: service,
      alt: "Professional & friendly service",
    },
    desc: "We're available 24/7 to assist you promptly. Our dedicated team is ready to address any queries or concerns at any time. Count on us for reliable support and exceptional service, day or night.",
  },
  {
    id: 6,
    name: "Cancelation and rates",
    logo: {
      image: cancel,
      alt: "Professional & friendly service",
    },
    desc: "Unexpected adjustments are accommodated by our accommodating cancellation policy. We recognize that circumstances might cause plans to alter, and we work to make the process simple. We value your satisfaction, therefore you can easily change or cancel your reservation.",
  },
];

const Why = () => {
  return (
    <>
      <section className="container mx-auto px-4 pt-10">
        <h2 className="curve-title">Why us?</h2>
        <div className="why">
          <div className="why-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-5 px-4">
            {data.slice(0, 3).map((el: any, key: any) => (
              <div className="content" key={key}>
                <img src={el.logo.image} alt={el.logo.alt} />
                <h4 className="whyTitle">{el.name}</h4>
                <p>{el.desc}</p>
              </div>
            ))}
          </div>
          <div className="why-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 py-5 px-4">
            {data.slice(3, 6).map((el: any, key: any) => (
              <div className="content" key={key}>
                <img src={el.logo.image} alt={el.logo.alt} />
                <h4 className="whyTitle">{el.name}</h4>
                <p>{el.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Why;
