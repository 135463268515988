import React from "react";
import "./styles/Main.scss";
import { Route, Routes, useLocation } from "react-router-dom";

// Pages imports
import Home from "./pages/Home";
import Trips from "./pages/Trips";
import Transfer from "./pages/Transfer";
import Contact from "./pages/Contact";
import Trip from "./pages/Trip";
import NotFound from "./pages/NotFound";

function App() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home />} />
      <Route path="/day-trips" element={<Trips />} />
      <Route path="/day-trips/:name" element={<Trip />} />
      <Route path="/transfers" element={<Transfer />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
