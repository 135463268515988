import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Image imports

import dubrovnik from "../../assets/img/dubrovnik.jpg";
import krka from "../../assets/img/krka2.jpg";
import right from "../../assets/img/icons/right-arrow.svg";
import left from "../../assets/img/icons/left-arrow.svg";
import Navbar from "../Navbar";

const TripsNav = () => {
  const ref = useRef(null);
  const ref2 = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
    ref2.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
    ref2.current.slickPrev();
  };

  const settings = {
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };
  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <section className="sliders">
        <div className="left-20 right-20 absolute nav top-0 z-50">
          <Navbar textColor={"white"} />
        </div>
        <Slider {...settings} ref={ref}>
          <div className="dubrovnik place">
            <div className="place-wrapper container mx-auto px-4">
              <h1 className="second-title main">0.1 Dubrovnik Town</h1>
              <p>
                Go on an adventure in Dubrovnik, where reality and fantasy
                collide! Discover where the Game of Thrones was filmed, stroll
                around the historic streets, and experience the thrill of the
                Iron Throne.
                <br />
                Prepare yourself for an extraordinary voyage where history,
                fantasy, and excitement converge!
              </p>
            </div>
          </div>
          <div className="krka place">
            <div className="place-wrapper container mx-auto px-4">
              <h1>0.2 Krka</h1>
              <p>
                I’a help ambitious business like your generate more profits by
                building awerness, driwing web trafic, connectig with customers
                and growing overall sales.
              </p>
            </div>
          </div>
          {/* <div className="plitvice place">
            <div className="place-wrapper container mx-auto px-4">
              <h1>0.3 Plitvice</h1>
              <p>
                I’a help ambitious business like your generate more profits by
                building awerness, driwing web trafic, connectig with customers
                and growing overall sales.
              </p>
            </div>
          </div> */}
        </Slider>
        <div className="small-slider">
          <div className="button gap-10 py-3">
            <button onClick={handlePrevSlide} className="px-2">
              <img src={left} alt="" />
            </button>
            <button onClick={handleNextSlide}>
              <img src={right} alt="" />
            </button>
          </div>
          <div>
            <Slider {...settings2} ref={ref2}>
              <img className="small" src={krka} />
              <img className="small" src={dubrovnik} />
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default TripsNav;
