import React from "react";

const Culture = () => {
  return (
    <>
      <section className="bg-culture">
        <div className="bg-section container mx-auto px-4">
          <span className="bg-blue py-1 px-2 mb-5 rounded text-white">
            Culture
          </span>
          <h2 className="second-title text-white">
            Learn about our culture<span className="text-blue">.</span>
          </h2>
          <p className="text-white w-3/5">
            Immerse yourself in our culture. Learn, experience, and embrace the
            rich heritage of Croatia. Uncover the captivating stories and
            traditions that define our cultural tapestry.
          </p>
        </div>
      </section>
    </>
  );
};

export default Culture;
