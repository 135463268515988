import React from "react";
import step1 from "../../assets/img/icons/click.svg";
import step2 from "../../assets/img/icons/fill2.svg";
import step3 from "../../assets/img/icons/call.svg";
import curveArrow from "../../assets/img/icons/curve-arrow.svg";
const How = () => {
  return (
    <>
      <div className="bg-bgColor">
        <section className="container mx-auto px-4 py-20">
          <h2 className="curve-title text-center">How to book?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-5">
            <div className="steps">
              <img src={step1} alt="" />
              <h4>Select your transfer</h4>
              <p>One of our great destinations</p>
              <img className="curveArrow" src={curveArrow} alt="Curve Arrow" />
            </div>
            <div className="steps">
              <img src={step2} alt="" />
              <h4>Fill your details</h4>
              <p>Your personal info</p>
              <img className="curveArrow" src={curveArrow} alt="Curve Arrow" />
            </div>
            <div className="steps">
              <img src={step3} alt="" />
              <h4>Wait for our approval</h4>
              <p>Fast and secure</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default How;
