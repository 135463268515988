import React from "react";
import bologn from "../../assets/img/bologn.jpg";
import fish from "../../assets/img/fish.jpg";
import hamburher from "../../assets/img/hamburger.jpg";
const Food = () => {
  return (
    <>
      <section className="tradition container mx-auto py-10 px-4">
        <h2 className="second-title text-black text-center">
          Taste our tradition<span className="text-blue">.</span>
        </h2>
        <div className="grid md:grid-cols-2  lg:grid-cols-3 py-20 items-center gap-5">
          <img className="rounded-xl mx-auto" src={bologn} alt="" />
          <img className="rounded-xl" src={fish} alt="" />
          <img className="rounded-xl mx-auto" src={hamburher} alt="" />
        </div>
      </section>
    </>
  );
};

export default Food;
