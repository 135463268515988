const places = [
  {
    name: "Dubrovnik",
    desc: "Lorem Ipsum Dolorem",
    route: "dubrovnik",
  },
  {
    name: "Krk",
    desc: "Lorem Ipsum Dolorem",
    route: "krk",
  },
  {
    name: "Ston",
    desc: "Lorem Ipsum Dolorem",
    route: "ston",
  },
];

export default places;
