import React, { useState } from "react";

const Navbar = ({ textColor }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <nav className="container mx-auto px-4 pt-5 flex items-center justify-between">
        <div className="logo text-blue">
          <a href="/">Pick&Drop</a>
        </div>
        <ul
          style={{ color: textColor }}
          className="as flex items-center gap-5 text-xl links"
        >
          <li>
            <a href={"/"}>Home</a>
          </li>
          <li>
            <a href={"/day-trips"}>Day Trips</a>
          </li>
          <li>
            <a href={"/transfers"}>Transfers</a>
          </li>
          <li className="bg-blue py-3 px-7 rounded-full">
            <a className="text-white" href={"/contact"}>
              Contact
            </a>
          </li>
        </ul>
        <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
          <ul>
            <li>
              <a href={"/"}>Home</a>
            </li>
            <li>
              <a href={"/day-trips"}>Day Trips</a>
            </li>
            <li>
              <a href={"/transfers"}>Transfers</a>
            </li>
            <li className="bg-blue py-3 px-7 rounded-full">
              <a className="text-white" href={"/contact"}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <svg
            className={
              isOpen ? "ham hamRotate180 ham5 active" : "ham hamRotate180 ham5"
            }
            viewBox="0 0 100 100"
            width="80"
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429"
            />
            <path className="line middle" d="m 70,50 h -40" />
            <path
              className="line bottom"
              d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429"
            />
          </svg>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
