import React from "react";
import { useParams } from "react-router-dom";
import places from "./api/places";

const Trip = () => {
  const { name } = useParams();

  if (name !== "dubrovnik" && name !== "krk" && name !== "ston") {
    // Display an error message or redirect to a default page
    window.location.href = "/contact";
  }

  console.log(places);

  return (
    <>
      {places.map((place: any, index: any) => {
        if (place.route === name) {
          return (
            <main key={index}>
              <h1 className="text-black">{place.name}</h1>
            </main>
          );
        }
      })}
    </>
  );
};

export default Trip;
