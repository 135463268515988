import React from "react";

const Contact = () => {
  return (
    <div className="text-center text-xl">
      <p>Soon online</p>
      <a className="text-blue underline" href="/">
        Back home
      </a>
    </div>
  );
};

export default Contact;
