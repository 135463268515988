import React from "react";
import Header from "../components/transfer/Header";

const Transfer = () => {
  return (
    <>
      <main>
        <Header />
      </main>
    </>
  );
};

export default Transfer;
