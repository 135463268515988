import React from "react";

const Footer = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23358.393138452182!2d16.715716!3d42.961437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134a71db969fc033%3A0x3f84237b1dc1db50!2sAtlas%20-%20Tourist%20Agency!5e0!3m2!1sen!2shr!4v1677084362512!5m2!1sen!2shr"
        style={{
          border: "0",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          height: "500px",
          margin: "50px 0 0 0",
        }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <footer className="bg-bgColor">
        <div className="footer-wrapper container mx-auto px-4">
          <div className="logo">
            <a className="text-blue" href="/">
              P&D
            </a>
          </div>
          <ul className="footer-links text-white items-center gap-5 justify-end">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">Day trips</a>
            </li>
            <li>
              <a href="/">Transfers</a>
            </li>
            <li>
              <a href="/">Terms and conditions</a>
            </li>
            <li>
              <a href="/">Policy and privacy</a>
            </li>
          </ul>
          <div className="handcrafted text-white">
            <p>
              HandCrafted by{" "}
              <a className="text-blue" href="https://technico.io">
                Technico.io
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
