import React from "react";

// Image imports

import dubrovnik from "../../assets/img/dubrovnik-trips.jpg";

interface Offers {
  name: string;
  route: string;
  bgImage: string;
  price: string;
  desc: string;
  class: string;
}

const offers: Offers[] = [
  {
    name: "Dubrovnik",
    route: "dubrovnik",
    bgImage: dubrovnik,
    price: "150€",
    desc: "Best to visit!",
    class: "best",
  },
  {
    name: "Krk",
    route: "krk",
    bgImage: dubrovnik,
    price: "120€",
    desc: "Most visited!",
    class: "best",
  },
  {
    name: "Ston",
    route: "ston",
    bgImage: dubrovnik,
    price: "100€",
    desc: "Best offer!",
    class: "most",
  },
];

const Travel = () => {
  return (
    <>
      <section className="container mx-auto px-4 travel">
        <h2 className="second-title text-black">
          Our offers<span className="text-blue">.</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {offers.map((el: any, index: number) => {
            return (
              <a
                className="relative"
                key={index}
                href={`day-trips/${el.route}`}
              >
                <div
                  className="card h-96 rounded-xl"
                  style={{
                    backgroundImage: `url(${el.bgImage})`,
                    textAlign: "center",
                  }}
                >
                  <div className="flex justify-between name-price">
                    <h3>{el.name}</h3>
                    <p className="bg-white py-1 px-2 mb-5 rounded text-black">
                      {el.price}
                    </p>
                  </div>

                  <div className={`desc rounded ${el.class}`}>
                    <p>{el.desc}</p>
                  </div>
                </div>
                <button className="bg-blue py-3 px-7 rounded-full hover-button">
                  <a className="text-white" href={"/contact"}>
                    Click 🫣🫣
                  </a>
                </button>
              </a>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Travel;
