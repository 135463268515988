import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Culture from "../components/trips/Culture";
import Food from "../components/trips/Food";
import Header from "../components/trips/Header";
import Travel from "../components/trips/Travel";

const Trips = () => {
  return (
    <>
      <Navbar textColor={"black"} />
      <Header />
      <Culture />
      <Food />
      <Travel />
      <Footer />
    </>
  );
};

export default Trips;
