import React from "react";

const Form = () => {
  return (
    <>
      <h2 className="second-title text-center">
        Simple<span className="text-blue">.</span>
      </h2>
      <p className="text-center py-5">
        Type where you want to go and we shuttle you there.
      </p>
      <div className="sm:w-full lg:w-2/3 xl:w-1/2 mx-auto pb-24">
        <form action="" className="flex flex-col">
          <input type="text" placeholder="Starting place" />
          <input type="text" placeholder="Final destination" />
          <input type="email" placeholder="Email" />
          <input type="number" placeholder="Number of passengers" />
          <input type="date" placeholder="Date of travel" />
        </form>
        <button className="bg-blue py-3 mt-10 px-7 rounded-full">
          <a className="text-white" href={"/contact"}>
            Enquire now
          </a>
        </button>
      </div>
    </>
  );
};

export default Form;
