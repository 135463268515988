import React from "react";
import cities from "../../assets/img/cities.jpg";
import airport from "../../assets/img/airport.png";
import day from "../../assets/img/day.svg";
const Destination = () => {
  return (
    <>
      <div className="container mx-auto px-4 pt-10">
        <h2 className="curve-title">Destinations</h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="content-section">
            <h3 className="second-title">
              Driving you trough cities you want.
            </h3>
            <p className="mt-8 mb-4">
              Set out on a personalized city adventure in Croatia. Choose your
              preferred locations by yourself and get lost in Croatia's dynamic
              cities.
            </p>
            <p className="mb-8">
              Discover hidden gems, savor regional cuisine, and embrace the
              vibrant culture that greets you as you explore at your own speed.
              Prepare to take the wheel as you travel through the captivating
              cities of Croatia and make lifelong memories!
            </p>

            <button className="bg-blue py-3 px-7 rounded-full big-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
          <div>
            <img src={cities} alt="Custom routes and transfers" />

            <button className="bg-blue py-3 px-7 rounded-full hidden small-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5 reverse pt-10 pb-10">
          <div>
            <img src={airport} alt="Custom routes and transfers" />
            <button className="bg-blue py-3 px-7 rounded-full hidden small-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
          <div className="content-section">
            <h3 className="second-title">
              From airport to hotel and inversely.
            </h3>
            <p className="mt-8 mb-4">
              We guarantee a smooth ride from the airport to your hotel and back
              under our "Smooth Transfers" section, which you can find here. As
              soon as you arrive, our dependable transportation services will
              whisk you away for a relaxing and hassle-free ride to your
              lodging, so leave the tension at the door.
            </p>
            <p className="mb-8">
              And when it's time to say goodbye to your destination, we'll be
              there to make sure you have a comfortable and easy ride back to
              the airport. Relax, sit back, and let us handle your
              transportation requirements so that your arrival and departure go
              as smoothly as possible. Our major goals throughout your entire
              trip will be your comfort and peace of mind.
            </p>
            <button className="bg-blue py-3 px-7 rounded-full big-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="content-section">
            <h3 className="second-title">We make a day trip just for you.</h3>
            <p className="mt-8 mb-4">
              We guarantee a smooth ride from the airport to your hotel and back
              under our "Smooth Transfers" section, which you can find here. As
              soon as you arrive, our dependable transportation services will
              whisk you away for a relaxing and hassle-free ride to your
              lodging, so leave the tension at the door.
            </p>
            <p className="mb-8">
              And when it's time to say goodbye to your destination, we'll be
              there to make sure you have a comfortable and easy ride back to
              the airport. Relax, sit back, and let us handle your
              transportation requirements so that your arrival and departure go
              as smoothly as possible. Our major goals throughout your entire
              trip will be your comfort and peace of mind.
            </p>
            <button className="bg-blue py-3 px-7 rounded-full big-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
          <div>
            <img
              className="w-full mb-5"
              src={day}
              alt="Custom routes and transfers"
            />
            <button className="bg-blue py-3 px-7 rounded-full hidden small-res">
              <a className="text-white" href={"/contact"}>
                Enquire now
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Destination;
